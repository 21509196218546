import React, { useState, useEffect } from 'react';
import AuthModal from './Authmodal';
import { auth } from '../firebaseConfig';
import { signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut } from 'firebase/auth';
import '../styles/navbar.css';

function Navbar() {
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);
  const [profilePic, setProfilePic] = useState('default-avatar.png');
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [profileDropdownVisible, setProfileDropdownVisible] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setProfilePic(currentUser.photoURL || 'default-avatar.png');
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const toggleMobileMenu = () => setMobileMenuOpen(!mobileMenuOpen);
  const toggleProfileDropdown = () => setProfileDropdownVisible(!profileDropdownVisible);

  const handleProfileClick = () => {
    if (user) {
      toggleProfileDropdown();
    } else {
      handleOpenModal();
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      handleCloseModal();
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  return (
    <>
      <nav className={`navbar fixed w-full z-10 top-0 bg-gray-900`} data-scroll-section>
        <div className="container mx-auto flex items-center justify-between px-4 py-2">
          <a className="text-white text-xl font-bold" href="#header">
            Sheela Packers and Movers
          </a>
          <button
            className={`mobile-menu-icon lg:hidden block ${mobileMenuOpen ? 'open' : ''}`}
            type="button"
            aria-label="Toggle navigation"
            onClick={toggleMobileMenu}
          >
            {mobileMenuOpen ? (
              <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg className="h-6 w-6 fill-current" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path d="M4 5h16M4 12h16m-7 7h7" />
              </svg>
            )}
          </button>
          <div className={`navbar-menu ${mobileMenuOpen ? 'mobile-active' : ''} lg:flex lg:items-center lg:w-auto w-full`} id="navbarNav">
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              {['home', 'about', 'services', 'resources', 'clients', 'network', 'contact'].map((section) => (
                <li className="nav-item" key={section}>
                  <a
                    className="px-3 py-2 flex items-center text-sm uppercase font-bold leading-snug text-white hover:text-gray-400"
                    href={`#${section}`}
                    onClick={toggleMobileMenu} // Collapse the menu on click
                  >
                    {section.charAt(0).toUpperCase() + section.slice(1)}
                  </a>
                </li>
              ))}
              <li className="nav-item">
                <div className="flex items-center relative" onClick={handleProfileClick}>
                  <img
                    src={profilePic}
                    alt="Profile"
                    className="profile-pic"
                  />
                  <span className="ml-2 text-white">{user ? user.displayName : 'Profile'}</span>
                  {user && (
                    <div className={`profile-dropdown ${profileDropdownVisible ? 'visible' : ''}`}>
                      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                      <a onClick={handleLogout} className="hover:bg-gray-700">Logout</a>
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <AuthModal isOpen={showModal} onClose={handleCloseModal} onGoogleSignIn={handleGoogleSignIn} />
    </>
  );
}

export default Navbar;
