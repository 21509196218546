// src/components/WhyChooseUs.js

import { useEffect } from 'react';
import React from 'react';
import '../styles/why-choose-us.css'
import AOS from 'aos';
function WhyChooseUs(){
  useEffect(() => {
    AOS.init(); // Initialize AOS if using
  }, []);
  return (
    <section id='why' className="why-choose-us py-5" data-scroll-section>
      <div className="container">
        <h2 className="text-center">Why Choose Us</h2>
        <div className="row">
          <div className="col-md-4 text-center" data-scroll data-scroll-speed="1">
            <i className="fas fa-truck-moving fa-3x mb-3"></i>
            <h3>20+ Years of Experience</h3>
            <p>Expertise and knowledge in the moving industry.</p>
          </div>
          <div className="col-md-4 text-center" data-scroll data-scroll-speed="2">
            <i className="fas fa-shield-alt fa-3x mb-3"></i>
            <h3>Fully Licensed and Insured</h3>
            <p>Peace of mind with our fully insured services.</p>
          </div>
          <div className="col-md-4 text-center" data-scroll data-scroll-speed="3">
            <i className="fas fa-headset fa-3x mb-3"></i>
            <h3>24/7 Customer Support</h3>
            <p>Always available to assist you with your move.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
