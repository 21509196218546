import React from 'react';
import '../styles/resources.css'

const resourcesData = [
  {
    id: 1,
    title: 'Expert Tips',
    image: 'https://plus.unsplash.com/premium_photo-1661695328405-ed45bc0a04c2?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8ZXhwZXJ0JTIwdGlwJTIwZm9yJTIwcGFja2VyJTIwYW5kJTIwbW92ZXJ8ZW58MHx8MHx8fDA%3D',
    details: [
      'Pre-Move Planning: Strategies for efficient move planning.',
      'Packing Techniques: Best practices for packing fragile items.',
      'Moving Day Tips: How to handle moving day stress and logistics.'
    ]
  },
  {
    id: 2,
    title: 'Checklists',
    image: 'https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTJ8fGxlZ2FsJTIwYWR2aWNlJTIwZm9yJTIwcGFja2VyJTIwYW5kJTIwbW92ZXJ8ZW58MHx8MHx8fDA%3D',
    details: [
      'Pre-Move Checklist: Tasks to complete before moving day.',
      'Moving Day Checklist: Essential items and tasks for the moving day.',
      'Post-Move Checklist: Steps to finalize your move and settle in.'
    ]
  },
  {
    id: 3,
    title: 'Guides',
    image: 'https://images.unsplash.com/photo-1643877323040-ebf5c69961dd?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8Z3VpZGVzJTIwZm9yJTIwcGFja2VyJTIwYW5kJTIwbW92ZXJ8ZW58MHx8MHx8fDA%3D',
    details: [
      'Moving Guide: Comprehensive guide on the entire moving process.',
      'Local Area Guide: Information on your new neighborhood.',
      'Settling In: Tips for adjusting to your new home and community.'
    ]
  },
  {
    id: 4,
    title: 'Packing Tips',
    image: 'https://plus.unsplash.com/premium_photo-1679858781690-71bffb438658?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTN8fGV4cGVydCUyMHRpcCUyMGZvciUyMHBhY2tlciUyMGFuZCUyMG1vdmVyfGVufDB8fDB8fHww',
    details: [
      'Supplies Checklist: Essential packing materials and tools.',
      'Room-by-Room Packing: Efficient packing strategies for each room.',
      'Labeling Tips: How to label boxes for easy unpacking.'
    ]
  },
  {
    id: 5,
    title: 'Moving Services',
    image: 'https://plus.unsplash.com/premium_photo-1682141929497-97402f35d45e?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NXx8bW92aW5nJTIwc2VydmljZXMlMjBmb3IlMjBwYWNrZXIlMjBhbmQlMjBtb3ZlcnxlbnwwfHwwfHx8MA%3D%3D',
    details: [
      'Professional Movers: Recommendations for reputable moving companies.',
      'DIY Moving: Tips and resources for a self-managed move.',
      'Special Services: Information on specialized moving services like packing or storage.'
    ]
  },
  {
    id: 6,
    title: 'Storage Solutions',
    image: 'https://images.unsplash.com/photo-1609143739217-01b60dad1c67?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OHx8c3RvcmFnZSUyMHNvbHV0aW9uJTIwZm9yJTIwcGFja2VyJTIwYW5kJTIwbW92ZXJ8ZW58MHx8MHx8fDA%3D',
    details: [
      'Self-Storage Units: Types and benefits of self-storage options.',
      'Climate-Controlled Storage: When and why to use climate-controlled units.',
      'Moving and Storage Companies: Companies offering combined moving and storage services.'
    ]
  },
  {
    id: 7,
    title: 'Legal Advice',
    image: 'https://images.unsplash.com/photo-1505664194779-8beaceb93744?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8bGVnYWwlMjBhZHZpY2UlMjBmb3IlMjBwYWNrZXIlMjBhbmQlMjBtb3ZlcnxlbnwwfHwwfHx8MA%3D%3D',
    details: [
      'Lease Agreements: Key considerations when reviewing a lease.',
      'Property Laws: Essential property laws affecting your move.',
      'Insurance: Types of insurance coverage needed for your move.'
    ]
  },
  {
    id: 8,
    title: 'Utility Setup',
    image: 'https://images.unsplash.com/photo-1497864768494-78100d1ddf01?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHV0aWxpdHklMjBzZXR1cCUyMGZvciUyMHBhY2tlciUyMGFuZCUyMG1vdmVyfGVufDB8fDB8fHww',
    details: [
      'Electricity and Water: Steps to transfer or set up utility services.',
      'Internet and Cable: How to schedule installations and transfers.',
      'Address Change: How to update your address with utility companies and services.'
    ]
  }
];
function Resources() {
  return (
    <section id="resources" className="py-12 bg-gray-100" data-scroll-section>
      <div className="container mx-auto px-4">
        <h2 className="text-center text-4xl font-bold mb-6" data-scroll data-scroll-speed="2">Resources</h2>
        <p className="text-center text-lg mb-10" data-scroll data-scroll-speed="1">
          Access useful resources and tips for a successful move.
        </p>
        <div className="flex flex-wrap -mx-4">
          {resourcesData.map((category) => (
            <div key={category.id} className="w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 px-4 mb-8">
              <div className="category resource-card" data-scroll data-scroll-speed="1">
                <div className="category-inner">
                  <img 
                    src={category.image} 
                    alt={category.title} 
                    className="rounded-lg"
                  />
                  <h4 className="category-title text-2xl font-semibold mb-3">{category.title}</h4>
                  <div className="category-description text-gray-700">
                    <ul className="list-disc list-inside text-left">
                      {category.details.map((detail, index) => (
                        <li key={index} className="mb-2">{detail}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Resources;