import React, { useState } from 'react';
import { db } from '../firebaseConfig'; // Import Firestore
import { collection, addDoc } from "firebase/firestore"; // Import necessary Firestore methods
import '../styles/contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    services: '',
    queryMessage: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      // Insert data into Firebase Firestore
      const docRef = await addDoc(collection(db, "contacts"), { // 'contacts' is the collection name
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        phone: formData.phone,
        gender: formData.gender,
        services: formData.services,
        queryMessage: formData.queryMessage
      });

      console.log("Document written with ID: ", docRef.id);

      // Clear form after submission
      setFormData({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        gender: '',
        services: '',
        queryMessage: ''
      });

      alert('Your query has been submitted successfully.');
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting your query. Please try again later.');
    }
  }

  return (
    <section id="contact" className="contact py-5" data-scroll-section>
      <div className="container mx-auto px-4">
        <h2 className="text-center text-3xl font-bold mb-5">Contact Us</h2>
        <div className="flex flex-wrap -mx-4">
          {/* Contact Information */}
          <div className="w-full md:w-1/2 px-4 mb-8 md:mb-0">
            <h4 className="text-xl font-semibold mb-4">Contact Information</h4>
            <ul className="space-y-4">
              {/* Address 1 */}
              <li className="flex">
                <i className="fas fa-map-marker-alt text-lg mr-4 text-gray-600"></i>
                <div>
                  <h5 className="font-bold">Head Office</h5>
                  <p>5, 19, 20, 26 827, Kh.No-58, Margondanahalli Village, Jinkethimmanahalli, Varanasi, KR Puram, Bengaluru Urban, Karnataka 560036</p>
                </div>
              </li>
              {/* Address 2 */}
              <li className="flex">
                <i className="fas fa-map-marker-alt text-lg mr-4 text-gray-600"></i>
                <div>
                  <h5 className="font-bold">Branch Office</h5>
                  <p>123, Sample Street, City Name, State, ZIP Code</p>
                </div>
              </li>
              {/* Address 3 */}
              <li className="flex">
                <i className="fas fa-map-marker-alt text-lg mr-4 text-gray-600"></i>
                <div>
                  <h5 className="font-bold">Warehouse</h5>
                  <p>456, Another Street, City Name, State, ZIP Code</p>
                </div>
              </li>
              {/* Phone Numbers */}
              <li className="flex">
                <i className="fas fa-phone text-lg mr-4 text-gray-600"></i>
                <div>
                  <h5 className="font-bold">Contact Numbers</h5>
                  <p>
                    <span>9694808730</span><br />
                    <span>7022475433</span><br />
                    <span>7891133458</span>
                  </p>
                </div>
              </li>
              {/* Email */}
              <li className="flex">
                <i className="fas fa-envelope text-lg mr-4 text-gray-600"></i>
                <div>
                  <h5 className="font-bold">Email</h5>
                  <p>sheelapackersmovers@gmail.com</p>
                </div>
              </li>
            </ul>
           {/* WhatsApp Button */}
            <div className="mt-8">
              <h4 className="text-xl font-semibold mb-4">Contact Us on WhatsApp</h4>
              <a 
                href="https://wa.me/+919694808730?text=Hello!%20I%20would%20like%20to%20inquire%20about%20your%20services."
                className="btn bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded inline-flex items-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-whatsapp fa-2x mr-2"></i> WhatsApp
              </a>
            </div>
          </div>
          {/* Submit Query Form */}
          <div className="w-full md:w-1/2 px-4">
            <form onSubmit={handleSubmit}>
              <h4 className="text-xl font-semibold mb-4">Submit Your Query</h4>
              <input type="hidden" id="service-input" name="service" />
              <div className="mb-4">
                <label htmlFor="firstName" className="form-label block mb-2 font-semibold">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="form-control block w-full p-2 border border-gray-300 rounded"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                  autoComplete="given-name"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="lastName" className="form-label block mb-2 font-semibold">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="form-control block w-full p-2 border border-gray-300 rounded"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                  autoComplete="family-name"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="form-label block mb-2 font-semibold">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="form-control block w-full p-2 border border-gray-300 rounded"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  autoComplete="email"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="form-label block mb-2 font-semibold">Phone Number</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className="form-control block w-full p-2 border border-gray-300 rounded"
                  value={formData.phone}
                  onChange={handleChange}
                  autoComplete="tel"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="gender" className="form-label block mb-2 font-semibold">Gender</label>
                <select
                  id="gender"
                  name="gender"
                  className="form-select block w-full p-2 border border-gray-300 rounded"
                  value={formData.gender}
                  onChange={handleChange}
                  required
                  autoComplete="sex"
                >
                  <option value="" disabled>Select...</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                  <option value="preferNotToSay">Prefer not to say</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="services" className="form-label block mb-2 font-semibold">Services</label>
                <select
                  id="services"
                  name="services"
                  className="form-select block w-full p-2 border border-gray-300 rounded"
                  value={formData.services}
                  onChange={handleChange}
                  required
                >
                  <option value="" disabled>Select a service...</option>
                  <option value="packingAndMoving">Packing and Moving</option>
                  <option value="loadingAndUnloading">Loading and Unloading</option>
                  <option value="warehousing">Warehousing</option>
                  <option value="carTransportation">Car Transportation</option>
                </select>
              </div>
              <div className="mb-4">
                <label htmlFor="queryMessage" className="form-label block mb-2 font-semibold">Your Message</label>
                <textarea
                  id="queryMessage"
                  name="queryMessage"
                  className="form-control block w-full p-2 border border-gray-300 rounded"
                  rows="4"
                  value={formData.queryMessage}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <div className="flex items-center">
                <button
                  type="submit"
                  className="btn bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
