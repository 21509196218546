import React, { useState, useEffect } from 'react';
import { db, auth } from '../firebaseConfig'; // Import Firebase configuration and auth
import { collection, addDoc, getDocs, serverTimestamp } from 'firebase/firestore';
import '../styles/testimonial.css';
import AOS from 'aos';

function Testimonials() {
  useEffect(() => {
    AOS.init(); // Initialize AOS if using
  }, []);
  // eslint-disable-next-line no-unused-vars
  const [testimonials, setTestimonials] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    location: '',
    comment: '',
    rating: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [user, setUser] = useState(null); // State to hold user information

  // Fetch testimonials from Firestore
  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'testimonials'));
        const testimonialsData = querySnapshot.docs.map((doc) => doc.data());
        setTestimonials(testimonialsData);
      } catch (error) {
        console.error('Error fetching testimonials:', error);
      }
    };

    fetchTestimonials();
  }, []);

  // Check for user authentication status
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user); // Set user state
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    if (!user) {
      setError('You must be logged in to submit feedback.');
      setIsSubmitting(false);
      return;
    }

    try {
      // Add a new document with a generated ID
      await addDoc(collection(db, 'testimonials'), {
        ...formData,
        createdAt: serverTimestamp(),
      });

      // Update local state with the new testimonial
      setTestimonials((prevTestimonials) => [...prevTestimonials, formData]);
      setFormData({ name: '', email: '', location: '', comment: '', rating: '' }); // Reset form
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setError('There was an issue submitting your feedback. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="testimonials-container">
      <div className="testimonials-section">
        <div className="testimonials-header">
          <h2 className="text-center mb-4">Testimonials</h2>
        </div>
        <div className="testimonials-list">
          {/* Sample Testimonial 1 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/men/32.jpg" className="testimonial-img" alt="Arjun Sharma" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"Sheela Packers and Movers made our move seamless and stress-free. Highly recommend their services!"</p>
                  <footer className="testimonial-author">Arjun Sharma, arjun.sharma@example.com, New Delhi, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9734;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>

          {/* Sample Testimonial 2 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/women/44.jpg" className="testimonial-img" alt="Sneha Verma" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"Excellent service and professional team. Will definitely use them again for future moves."</p>
                  <footer className="testimonial-author">Sneha Verma, sneha.verma@example.com, Mumbai, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>

          {/* Sample Testimonial 3 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/men/52.jpg" className="testimonial-img" alt="Ravi Patel" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"The team was very punctual and handled everything with care. Great job!"</p>
                  <footer className="testimonial-author">Ravi Patel, ravi.patel@example.com, Ahmedabad, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>

          {/* Sample Testimonial 4 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/women/65.jpg" className="testimonial-img" alt="Priya Singh" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"Amazing experience! The movers were friendly and efficient. Highly recommended."</p>
                  <footer className="testimonial-author">Priya Singh, priya.singh@example.com, Bangalore, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>

          {/* Sample Testimonial 5 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/men/42.jpg" className="testimonial-img" alt="Kumar Joshi" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"Very professional and quick service. Made my relocation hassle-free."</p>
                  <footer className="testimonial-author">Kumar Joshi, kumar.joshi@example.com, Chennai, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9734;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>

          {/* Sample Testimonial 6 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/women/58.jpg" className="testimonial-img" alt="Ananya Reddy" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"Exceptional service with attention to detail. Will use again for sure."</p>
                  <footer className="testimonial-author">Ananya Reddy, ananya.reddy@example.com, Hyderabad, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>

          {/* Sample Testimonial 7 */}
          <div className="testimonial-item">
            <div className="testimonial-content">
              <img src="https://randomuser.me/api/portraits/men/22.jpg" className="testimonial-img" alt="Amit Kumar" />
              <div className="testimonial-details">
                <blockquote className="blockquote">
                  <p className="testimonial-text mb-0">"Reliable and efficient service. The move was handled smoothly and without any issues."</p>
                  <footer className="testimonial-author">Amit Kumar, amit.kumar@example.com, Pune, India</footer>
                  <div className="stars">
                    &#9733;&#9733;&#9733;&#9733;&#9733;
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Feedback Form */}
      <div className="feedback-form">
        <h3>Submit Your Feedback</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Name"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="location">Location</label>
            <input
              type="text"
              id="location"
              name="location"
              value={formData.location}
              onChange={handleChange}
              placeholder="Location"
              className="form-control"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="comment">Your Feedback</label>
            <textarea
              id="comment"
              name="comment"
              value={formData.comment}
              onChange={handleChange}
              placeholder="Your feedback"
              className="form-control"
              rows="4"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="rating">Rating</label>
            <select
              id="rating"
              name="rating"
              value={formData.rating}
              onChange={handleChange}
              className="form-control"
              required
            >
              <option value="1">1 Star</option>
              <option value="2">2 Stars</option>
              <option value="3">3 Stars</option>
              <option value="4">4 Stars</option>
              <option value="5">5 Stars</option>
            </select>
          </div>
          {error && <p className="text-red-500 text-center">{error}</p>}
          <button
            type="submit"
            className={`btn-primary ${isSubmitting ? 'bg-gray-400 cursor-not-allowed' : ''}`}
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </button>
        </form>
      </div>
    </section>
  );
}

export default Testimonials;