import React, { useEffect, useRef } from 'react';
import "../styles/header.css";
import Typed from 'typed.js';

// Import local images
import aboutImage from '../images/About.jpeg';
import header1 from '../images/header_1.jpeg';
import header2 from '../images/header_2.jpeg';
import header3 from '../images/header_3.jpeg';
import header4 from '../images/header_4.jpeg';
import header5 from '../images/header_5.jpeg';
import header6 from '../images/header_6.jpeg';
import header7 from '../images/header_7.jpeg';
import header8 from '../images/header_8.jpeg';
import header9 from '../images/header_9.jpeg';
import header10 from '../images/Header_10.jpeg';
import header11 from '../images/Header_11.jpeg';
import home1 from '../images/Home_1.jpeg';
import home2 from '../images/Home_2.jpeg';

function Header() {
    const quoteRef = useRef(null);
    const heroRef = useRef(null);
    let currentIndex = 0;

    const backgrounds = [
        'https://images.unsplash.com/photo-1614359835514-92f8ba196357?q=80&w=1472&auto=format&fit=crop',
        'https://plus.unsplash.com/premium_photo-1680300960757-376ffe4a18ce?w=500&auto=format&fit=crop&q=60',
        'https://images.unsplash.com/photo-1666852976132-4a70734e9407?w=500&auto=format&fit=crop&q=60',
        'https://plus.unsplash.com/premium_photo-1664300914931-76c0f99a96b8?w=500&auto=format&fit=crop&q=60',
        aboutImage,
        header1,
        header2,
        header3,
        header4,
        header5,
        header6,
        header7,
        header8,
        header9,
        header10,
        header11,
        home1,
        home2
    ];


    useEffect(() => {
        function changeBackground() {
            if (heroRef.current) {
                heroRef.current.style.backgroundImage = `url(${backgrounds[currentIndex]})`;
                // eslint-disable-next-line react-hooks/exhaustive-deps
                currentIndex = (currentIndex + 1) % backgrounds.length;
            }
        }

        const backgroundInterval = setInterval(changeBackground, 5000);
        changeBackground();

        return () => clearInterval(backgroundInterval);
    }, []);

    useEffect(() => {
        if (quoteRef.current) {
            const options = {
                strings: [
                    "Your trusted partner for a seamless, stress-free relocation.",
                    "Where every detail is meticulously managed.",
                    "We make your move effortless and efficient."
                ],
                typeSpeed: 50,
                backSpeed: 25,
                backDelay: 1500,
                startDelay: 500,
                loop: true,
                cursorChar: '<span class="cursor"></span>' // Custom cursor
            };

            new Typed(quoteRef.current, options);
        }
    }, []);

    return (
        <header id="header" className="hero" ref={heroRef} data-scroll-section>
            <div className="container hero-content text-center text-white d-flex flex-column justify-content-center align-items-center">
                <h1 className="display-4 font-weight-bold mb-3">Sheela Packers and Movers</h1>
                <p id="quote" ref={quoteRef} className="typed-text lead"></p>
                <div className="btn-container mt-4">
                    <a href="#contact" className="btn btn-light btn-lg">Get a Free Quote</a>
                </div>
            </div>
        </header>
    );
}

export default Header;
