import React from 'react';
import '../styles/home.css'

function Home(){
  return (
    <section id="home" className="text-center py-5 bg-light" data-scroll-section>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-4">Welcome to Sheela Packers and Movers</h2>
        <p className="text-lg mb-4">
          Sheela Packers and Movers is your trusted partner for a seamless relocation experience. We specialize in both residential and commercial moving, offering customized solutions to ensure your belongings are transported safely and efficiently. Our professional team utilizes advanced technology, including real-time tracking systems, to provide transparency and peace of mind throughout the move. We are committed to excellence with meticulous packing, high-quality materials, and equipment that protect your items and ensure they arrive in pristine condition. At Sheela Packers and Movers, customer satisfaction is our priority, reflected in our personalized service and transparent pricing with no hidden fees. Whether you’re moving locally or across long distances, we strive to make your transition smooth and stress-free. Contact us today to learn more about our services and receive a free, no-obligation quote.
        </p>
        <p className="text-lg mb-4">
          We take pride in our reputation for excellence and the positive feedback we receive from our clients. Our success is built on a foundation of trust and reliability, and we are committed to maintaining the highest standards of service. By choosing Sheela Packers and Movers, you are choosing a company that values your time, your belongings, and your peace of mind.
        </p>
        <p className="text-lg mb-4">
          Thank you for considering Sheela Packers and Movers for your relocation needs. We look forward to the opportunity to serve you and make your move a seamless and positive experience. Contact us today to learn more about our services or to request a free, no-obligation quote. Let us handle the logistics while you focus on starting your new chapter with confidence.
        </p>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4">
            <img src='https://plus.unsplash.com/premium_photo-1680300960757-376ffe4a18ce?w=500&auto=format&fit=crop&q=60' alt="Reliable Moving Services" className="img-fluid rounded-lg w-full h-auto" />
            <p className="mt-2">
              Our advanced logistics solutions and real-time tracking systems ensure the safety and timely delivery of your belongings. With a focus on cutting-edge technology and meticulous planning, we guarantee a moving experience that is both efficient and reliable.
            </p>
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4">
            <img src='https://plus.unsplash.com/premium_photo-1664300914931-76c0f99a96b8?w=500&auto=format&fit=crop&q=60' alt="Professional Team" className="img-fluid rounded-lg w-full h-auto" />
            <p className="mt-2">
              Our dedicated team of experts provides meticulous care and personalized service, ensuring a stress-free moving experience. From packing to unpacking, our professionals are committed to delivering exceptional service with attention to detail.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
