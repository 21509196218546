import React, { useState, useEffect } from 'react';
import { XIcon, EyeIcon, EyeOffIcon } from '@heroicons/react/solid';
import { FaGoogle } from 'react-icons/fa';
import { auth } from '../firebaseConfig';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  fetchSignInMethodsForEmail,
} from 'firebase/auth';
import '../styles/authmodal.css';

const AuthModal = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isEmailChecked, setEmailChecked] = useState(false);
  const [isRegistered, setRegistered] = useState(null);
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  useEffect(() => {
    if (password) {
      checkPasswordStrength(password);
    } else {
      setPasswordStrength('');
    }
  }, [password]);

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const checkEmail = async () => {
    setLoading(true);
    setError(null);
    try {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setRegistered(true);
      } else {
        setRegistered(false);
      }
    } catch (error) {
      setError('Something went wrong. Please try again.');
    } finally {
      setLoading(false);
      setEmailChecked(true);
    }
  };

  const handleContinue = () => {
    if (validateEmail(email)) {
      checkEmail();
    } else {
      setError('Please enter a valid email address.');
    }
  };

  const handleLogin = async () => {
    setLoading(true);
    setError(null);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      onClose();
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async () => {
    setLoading(true);
    setError(null);
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      onClose();
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    setLoading(true);
    setError(null);
    try {
      await signInWithPopup(auth, provider);
      onClose();
    } catch (error) {
      handleAuthError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleAuthError = (error) => {
    switch (error.code) {
      case 'auth/wrong-password':
        setError('Invalid credentials. Please try again.');
        break;
      case 'auth/user-not-found':
        setError('No account found with this email. Please sign up.');
        break;
      case 'auth/email-already-in-use':
        setError('An account with this email already exists.');
        break;
      case 'auth/weak-password':
        setError('Password should be at least 6 characters.');
        break;
      default:
        setError('Something went wrong. Please try again.');
    }
  };

  const checkPasswordStrength = (password) => {
    if (password.length < 6) {
      setPasswordStrength('Weak');
    } else if (password.length < 10) {
      setPasswordStrength('Medium');
    } else {
      setPasswordStrength('Strong');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300 ease-in-out">
      <div className="bg-white w-full max-w-lg p-6 rounded-lg shadow-lg relative">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-bold">
            {isEmailChecked
              ? isRegistered
                ? 'Log in to your account'
                : 'Create an account'
              : 'Welcome'}
          </h2>
          <button onClick={onClose} className="text-gray-600 hover:text-gray-900">
            <XIcon className="h-6 w-6" />
          </button>
        </div>
        {!isEmailChecked ? (
          <>
            <p className="text-sm text-gray-500 mb-4">
              Get unlimited access to all features by creating an account or logging in.
            </p>
            <div className="mb-6">
              <input
                type="email"
                placeholder="Email Address"
                className="form-control form-control-lg"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setError(null);
                }}
              />
              {error && <p className="text-danger mt-2">{error}</p>}
              <button
                className="btn btn-dark w-100 mt-4"
                onClick={handleContinue}
                disabled={loading}
              >
                {loading ? <span className="spinner-border spinner-border-sm" /> : 'Continue'}
              </button>
              <div className="text-center my-4">
                <span className="text-muted">or</span>
              </div>
              <button
                className="btn btn-outline-secondary w-100"
                onClick={handleGoogleLogin}
                disabled={loading}
              >
                {loading ? <span className="spinner-border spinner-border-sm" /> : <>
                  <FaGoogle className="text-danger me-2" />
                  Sign in with Google
                </>}
              </button>
            </div>
          </>
        ) : (
          <>
            {isRegistered ? (
              <>
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  className="form-control form-control-lg mb-4"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(null);
                  }}
                />
                <button
                  className="absolute right-0 top-0 mr-4 mt-4"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                </button>
                {password && <p className={`text-sm mt-1 ${passwordStrength === 'Strong' ? 'text-green-500' : passwordStrength === 'Medium' ? 'text-yellow-500' : 'text-red-500'}`}>{passwordStrength}</p>}
                <button
                  className="btn btn-dark w-100"
                  onClick={handleLogin}
                  disabled={loading}
                >
                  {loading ? <span className="spinner-border spinner-border-sm" /> : 'Log In'}
                </button>
              </>
            ) : (
              <>
                <p className="text-sm text-gray-500 mb-4">
                  Create a new account with <strong>{email}</strong>
                </p>
                <input
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Create Password"
                  className="form-control form-control-lg mb-4"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError(null);
                  }}
                />
                <button
                  className="absolute right-0 top-0 mr-4 mt-4"
                  onClick={togglePasswordVisibility}
                >
                  {passwordVisible ? <EyeOffIcon className="h-5 w-5 text-gray-500" /> : <EyeIcon className="h-5 w-5 text-gray-500" />}
                </button>
                {password && <p className={`text-sm mt-1 ${passwordStrength === 'Strong' ? 'text-green-500' : passwordStrength === 'Medium' ? 'text-yellow-500' : 'text-red-500'}`}>{passwordStrength}</p>}
                <button
                  className="btn btn-dark w-100"
                  onClick={handleSignUp}
                  disabled={loading}
                >
                  {loading ? <span className="spinner-border spinner-border-sm" /> : 'Sign Up'}
                </button>
              </>
            )}
            <button
              className="btn btn-outline-secondary w-100 mt-4"
              onClick={() => setEmailChecked(false)}
              disabled={loading}
            >
              Back
            </button>
          </>
        )}
        {error && <p className="text-danger mt-4">{error}</p>}
      </div>
    </div>
  );
};

export default AuthModal;
