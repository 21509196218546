import React, { useEffect, useState,} from 'react';

import { auth } from './firebaseConfig';  // Import Firebase auth
import Navbar from './components/Navbar';
import Header from './components/Header';
import Home from './components/Home';
import Network from './components/Network';
import Clients from './components/Clients';
import Resources from './components/Resources';
import WhyChooseUs from './components/WhyChooseUs';
import About from './components/About';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import AuthModal from './components/Authmodal';
import './index.css';
import './App.css';

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
    
  useEffect(() => {
    const checkAuthStatus = async () => {
      // Check Firebase authentication status
      auth.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser) {
          setUser(firebaseUser);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      });
    };
    checkAuthStatus();
  },);

  // Array of components to render
  const sectionComponents = [
    <Home key="home" />,
    <Services key="services" />,
    <Resources key="resources" />,
    <Clients key="clients" />,
    <WhyChooseUs key="whychooseus" />,
    <Network key="network" />,
    <Testimonials key="testimonials" />,
    <About key="about" />,
    <Contact key="contact" />,
  ];

  return (
    <div className="main-container">
      <Navbar isAuthenticated={isAuthenticated} user={user} />
      <Header />
      <div className="content-wrapper">
        {sectionComponents.map((section) => (
          <React.Fragment key={section.key}>{section}</React.Fragment>
        ))}
      </div>
      {isModalOpen && (
        <AuthModal
          setIsAuthenticated={setIsAuthenticated}
          setUser={setUser}
          closeModal={() => setIsModalOpen(false)}
        />
      )}
      <Footer />
    </div>
  );
};

export default App;
