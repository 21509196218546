import React, { useState } from 'react';
import '../styles/services.css'; // Ensure this path is correct

const servicesData = [
  {
    title: 'Residential Moving',
    image: 'https://plus.unsplash.com/premium_photo-1683141107777-8309fc981326?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    detail: 'Our expert team handles local and long-distance household moves with precision and care. We ensure a smooth transition for your family, managing everything from packing to transportation.',
  },
  {
    title: 'Commercial Moving',
    image: 'https://images.unsplash.com/photo-1600725935160-f67ee4f6084a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8NXx8Y29tbWVyaWNhbCUyMG1vdmluZ3xlbnwwfHwwfHx8MA%3D%3D',
    detail: 'We provide comprehensive office relocation services, minimizing downtime and ensuring your business operations continue smoothly. Our team is equipped to handle all your commercial moving needs.',
  },
  {
    title: 'Storage Solutions',
    image: 'https://images.unsplash.com/photo-1469289759076-d1484757abc3?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8M3x8U2VjdXJlJTIwc2hvcnQlMjB0ZXJtJTIwYW5kJTIwbG9uZyUyMHRlcm0lMjBzdG9yYWdlJTIwb3B0aW9uc3xlbnwwfHwwfHx8MA%3D%3D',
    detail: 'Our secure storage facilities offer short-term and long-term storage options, ensuring your belongings are safe and accessible. We provide climate-controlled units for sensitive items.',
  },
  {
    title: 'Packing Services',
    image: 'https://plus.unsplash.com/premium_photo-1676057875109-6899ca4ae838?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8OXx8cGFja2luZ3xlbnwwfHwwfHx8MA%3D%3D',
    detail: 'Our professional packing services ensure your items are securely packed, using high-quality materials to prevent damage during transit. We handle everything from fragile items to bulky furniture.',
  },
  {
    title: 'Unpacking Services',
    image: 'https://plus.unsplash.com/premium_photo-1670002259521-345ab6bb758a?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8MXx8dW5wYWNraW5nfGVufDB8fDB8fHww',
    detail: 'Our team provides efficient unpacking services, helping you settle into your new home quickly. We organize your items as per your preferences, making your new space comfortable and functional.',
  },
  {
    title: 'Specialized Moving',
    image: 'https://images.unsplash.com/photo-1615095432023-77f17ef6fab8?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8NHx8ZGVsaWNhdGUlMjBtb3Zpbmd8ZW58MHx8MHx8fDA%3D',
    detail: 'We offer specialized moving services for items that require extra care, such as pianos, artwork, antiques, and other valuable possessions. Our trained professionals use advanced techniques to ensure safe transport.',
  },
  {
    title: 'Vehicle Transporting',
    image: 'https://images.unsplash.com/photo-1534081278205-74cdb8585879?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8fHxzZWFyY2h8MTZ8fGNhciUyMHRyYW5zcG9ydHxlbnwwfHwwfHx8MA%3D%3D',
    detail: 'Our vehicle transporting services ensure your car, motorcycle, or other vehicles are moved safely and efficiently to your new location. We provide both open and enclosed transport options to suit your needs.',
  },
];

const Services = () => {
  const [expandedService, setExpandedService] = useState(null);

  const handleServiceClick = (serviceName) => {
    setExpandedService(expandedService === serviceName ? null : serviceName);
  };

  const ServiceCard = ({ service, isExpanded, onClick }) => (
    <div 
      className="card" 
      onClick={() => onClick(service.title)}
    >
      <img 
        src={service.image} 
        className="card-img-top" 
        alt={service.title} 
      />
      <div className="card-body">
        <h3 className="card-title">{service.title}</h3>
      </div>
      <div 
        className={`card-details ${isExpanded ? 'show' : ''}`}
      >
        <p className="card-detail-text">{service.detail}</p>
      </div>
    </div>
  );

  return (
    <section id="services" className="py-10 bg-gray-100" data-scroll-section>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-8">Our Services</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {servicesData.map(service => (
            <ServiceCard 
              key={service.title} 
              service={service} 
              isExpanded={expandedService === service.title} 
              onClick={handleServiceClick} 
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;