import React from 'react';
import 'locomotive-scroll/dist/locomotive-scroll.css';

function About() {
  
  return (
    <section id="about" className="py-20 bg-gradient-to-b from-blue-100 to-blue-50" data-scroll-section>
      <div className="container mx-auto px-4">
        <h2
          className="text-center text-4xl font-extrabold text-gray-800 mb-12"
          data-scroll
          data-scroll-speed="2"
        >
          About Us
        </h2>
        <p
          className="text-center text-xl text-gray-700 leading-relaxed mb-12 max-w-2xl mx-auto"
          data-scroll
          data-scroll-speed="1"
        >
          With over 20 years of expertise, Sheela Packers and Movers is a leader in providing high-quality moving services. 
          We are committed to ensuring your relocation is smooth and stress-free through meticulous planning, advanced technology, 
          and a dedicated team of professionals. Our comprehensive services cater to both residential and commercial clients, 
          emphasizing reliability, efficiency, and customer satisfaction. From packing and logistics to safe delivery, we handle 
          every aspect of your move with the utmost care and precision. Our team, equipped with extensive training and experience, 
          is dedicated to delivering exceptional service tailored to your unique needs. Trust Sheela Packers and Movers to turn 
          your moving challenges into a seamless experience.
        </p>
        <div className="text-center">
          <div className="overflow-hidden rounded-lg shadow-xl transform hover:scale-105 transition-transform duration-500 ease-in-out">
            <img
              src='https://images.unsplash.com/photo-1614359835514-92f8ba196357?q=80&w=1472&auto=format&fit=crop'
              alt="Our Team"
              className="mx-auto"
              data-scroll
              data-scroll-speed="3"
            />
          </div>
          <p
            className="mt-6 text-xl text-gray-600 leading-relaxed max-w-xl mx-auto"
            data-scroll
            data-scroll-speed="1"
          >
            Our professional team at Sheela Packers and Movers, committed to delivering exceptional service and ensuring your move is handled with care and precision.
          </p>
        </div>
      </div>
    </section>
  );
}

export default About;
