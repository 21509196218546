import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import AuthModal from './Authmodal';  // Ensure the path is correct
import { db } from '../firebaseConfig';  // Ensure the path is correct
import { collection, addDoc } from 'firebase/firestore';
import '../styles/footer.css';  // Ensure the path is correct

function Footer() {
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await addDoc(collection(db, 'subscriptions'), {
        email: email,
        timestamp: new Date(),
      });

      alert('Thank you for subscribing!');
      setEmail('');
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('There was an error with your subscription. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <footer className="footer bg-dark text-light py-4">
      <div className="container">
        <div className="row">
          {/* Navigation Links and Company Name */}
          <div className="col-12 col-md-4 mb-3">
            <h5 className="text-uppercase mb-3">Sheela Packers and Movers</h5>
            <div className="nav-links d-flex justify-content-center flex-wrap">
              <a href="#about" className="nav-link">About Us</a>
              <a href="#services" className="nav-link">Services</a>
              <a href="#contact" className="nav-link">Contact</a>
              <button onClick={handleOpenModal} className="btn btn-light">
                Log in
              </button>
              <AuthModal isOpen={showModal} onClose={handleCloseModal} />
            </div>
          </div>

          {/* Follow Us Section */}
          <div className="col-12 col-md-4 mb-3 text-center">
            <h5 className="text-uppercase mb-3">Follow Us</h5>
            <div className="social-icons d-flex justify-content-center">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" className="social-icon">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>

          {/* Subscribe Form Section */}
          <div className="col-12 col-md-4 mb-3 d-flex justify-content-end">
            <div className="subscribe">
              <h5 className="text-uppercase mb-3">Subscribe</h5>
              <form onSubmit={handleSubscribe} className="d-flex">
                <input
                  type="email"
                  name="email"
                  id="subscribe-email"
                  className="form-control"
                  placeholder="Your email address"
                  aria-label="Your email address"
                  aria-describedby="button-addon2"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  disabled={isSubmitting}
                />
                <button className="btn btn-primary" type="submit" id="button-addon2" disabled={isSubmitting}>
                  {isSubmitting ? 'Submitting...' : 'Subscribe'}
                </button>
              </form>
            </div>
          </div>
        </div>

        <hr className="my-4 border-light" />

        <div className="footer-text text-center">
          <p className="mb-0">&copy; 2024 Sheela Packers and Movers. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
