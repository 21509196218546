import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaMapMarkerAlt } from 'react-icons/fa';
import '../styles/network.css'; // Ensure the path is correct
import AOS from 'aos'; // Import AOS if using

const Network = () => {
  useEffect(() => {
    AOS.init(); // Initialize AOS if using
  }, []);

  return (
    <section id="network" data-aos="fade-up" className="network-section">
      <div className="container">
        <h2 className="text-center">Our Network</h2>
        <p className="text-center">
          With a vast network spread across India, we are equipped to handle your move with exceptional efficiency. Our modern facilities and strategic locations ensure that we provide reliable and convenient services wherever you are.
        </p>
        <div className="row">
          {/* Define network locations here */}
          {[
            {
              href: "https://www.google.com/maps/place/Kh.No-58,+Margondanahalli+Village,+Jinkethimmanahalli,+Varanasi",
              title: "Kh.No-58, Margondanahalli Village",
              location: "Jinkethimmanahalli, Varanasi",
            },
            {
              href: "https://www.google.com/maps/place/KR+Puram,+Bengaluru+Urban,+Karnataka+560036",
              title: "KR Puram",
              location: "Bengaluru Urban, Karnataka 560036",
            },
            {
              href: "https://www.google.com/maps/place/5th+Cross,+2nd+Block,+Jayanagar,+Bengaluru,+Karnataka+560041",
              title: "5th Cross, 2nd Block",
              location: "Jayanagar, Bengaluru, Karnataka 560041",
            },
            {
              href: "https://www.google.com/maps/place/Plot+No.+45,+Sector+15,+Faridabad,+Haryana+121002",
              title: "Plot No. 45",
              location: "Sector 15, Faridabad, Haryana 121002",
            },
            {
              href: "https://www.google.com/maps/place/Office+8,+Santacruz+East,+Mumbai,+Maharashtra+400055",
              title: "Office 8",
              location: "Santacruz East, Mumbai, Maharashtra 400055",
            },
            {
              href: "https://www.google.com/maps/place/Unit+7,+3rd+Floor,+Park+Street,+Kolkata,+West+Bengal+700016",
              title: "Unit 7, 3rd Floor",
              location: "Park Street, Kolkata, West Bengal 700016",
            }
          ].map((place, index) => (
            <div className="col-md-3 text-center" key={index}>
              <a href={place.href} target="_blank" rel="noopener noreferrer">
                <div className="d-flex flex-column align-items-center">
                  <FaMapMarkerAlt className="fa-3x mb-2" />
                  <h5>{place.title}</h5>
                  <p>{place.location}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Network;
