import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import '../styles/clients.css';

function Clients() {
  const allClients = [
    {
      src: 'https://imgs.search.brave.com/pb57SkBukLBuPPMFV89YifDzq7ekDnLuwWHH40U2fVI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jb21w/YW5pZXNsb2dvLmNv/bS9pbWcvb3JpZy9U/Q1MuTlNfQklHLTg5/YzUwZTM5LnBuZz90/PTE3MjAyNDQ0OTQ',
      alt: 'Tata Consultancy Services',
      description: 'Tata Consultancy Services - A global leader in IT services and consulting.',
      url: 'https://www.tcs.com',
    },
    {
      src: 'https://imgs.search.brave.com/dCHaEZSq9Z3kGe3o15YLP2so_LJxRjZM7kMehhtLgAg/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9tZWRp/YS5nZXR0eWltYWdl/cy5jb20vaWQvOTQy/Njg4MTU4L3Bob3Rv/L2JlbmdhbHVydS1p/bmRpYS1pbmZvc3lz/LWNhbXB1cy1vbi1h/cHJpbC0xNS0yMDE2/LWluLWJlbmdhbHVy/dS1pbmRpYS5qcGc_/cz02MTJ4NjEyJnc9/MCZrPTIwJmM9SFg3/bFd5YmRUWWpzQVM0/Z3JuR0YwdF9KU3F5/MWM3cEdyS2J5b29I/RzNzTT0',
      alt: 'Infosys',
      description: 'Infosys - Leading provider of IT and business consulting services.',
      url: 'https://www.infosys.com',
    },
    {
      src: 'https://imgs.search.brave.com/0hKW9DVYqX_zqQ35aExexMt1aucQZBboY-cl_2Lm9sI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/bG9vay5uZXQvd3At/Y29udGVudC91cGxv/YWRzLzIwMjIvMDgv/V2lwcm8tTG9nby5w/bmc',
      alt: 'Wipro',
      description: 'Wipro - Expert in IT consulting, system integration, and outsourcing services.',
      url: 'https://www.wipro.com',
    },
    {
      src: 'https://imgs.search.brave.com/2nf7u6Y8eVI-PLl2nK_bGeyIYVsxz9DneAsaS4k0VMo/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/LWxvZ29zLmNvbS8y/MDE2LzEwL0lDSUNJ/X2JhbmtfbG9nby5w/bmc',
      alt: 'ICICI Bank',
      description: 'ICICI Bank - Major financial institution with a wide range of banking and insurance services.',
      url: 'https://www.icicibank.com',
    },
    {
      src: 'https://imgs.search.brave.com/CuFm0xT6iFuNhZ2M9SaTk-4XHxWQolTEovf6aJ1Xi_I/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9zZWVr/bG9nby5jb20vaW1h/Z2VzL0gvaGRmYy1i/YW5rLWxvZ28tQTBB/MkNERTc5My1zZWVr/bG9nby5jb20ucG5n',
      alt: 'HDFC Bank',
      description: 'HDFC Bank - Renowned private sector bank offering comprehensive financial services.',
      url: 'https://www.hdfcbank.com',
    },
    {
      src: 'https://imgs.search.brave.com/cOs66r1sEoSdYraq5Uio0DVM0Ol8xDli0kYxFvXOoBM/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9sb2dv/dGFnbGluZXMuY29t/L3dwLWNvbnRlbnQv/dXBsb2Fkcy8yMDE2/LzA3L3N0YXRlLWJh/bmstb2YtaW5kYWkt/bmV3LWxvZ28tYWZ0/ZXItbWVyZ2VyLmpw/Zw',
      alt: 'State Bank of India',
      description: 'State Bank of India - India\'s largest public sector bank with extensive services.',
      url: 'https://www.onlinesbi.com',
    },
    {
      src: 'https://imgs.search.brave.com/CcHJA8cIVegzOJeHJt3CBArpDE5O4nnmkbhs63jv0jU/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly93d3cu/cG5nbWFydC5jb20v/ZmlsZXMvMTcvTWFy/dXRpLVN1enVraS1M/b2dvLVRyYW5zcGFy/ZW50LUJhY2tncm91/bmQucG5n',
      alt: 'Maruti Suzuki',
      description: 'Maruti Suzuki - Leading automobile manufacturer focusing on innovation and quality.',
      url: 'https://www.marutisuzuki.com',
    },
    {
      src: 'https://imgs.search.brave.com/siRbWUlsB9t1k7bM0kACdUrQ5Plicv1_OXO7dfqsSgI/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly8xMDAw/bG9nb3MubmV0L3dw/LWNvbnRlbnQvdXBs/b2Fkcy8yMDIzLzA2/L0FpcnRlbC1sb2dv/LTUwMHgyODEucG5n',
      alt: 'Bharti Airtel',
      description: 'Bharti Airtel - Major telecom service provider with extensive network coverage.',
      url: 'https://www.airtel.in',
    },
    {
      src: 'https://imgs.search.brave.com/VLQVji3a3l3ZGIvAt_wUtjGyYTC2GT6pzEfp9m5RLNo/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly9jZG4u/ZnJlZWJpZXN1cHBs/eS5jb20vbG9nb3Mv/bGFyZ2UvMngvZmxp/cGthcnQtbG9nby1w/bmctdHJhbnNwYXJl/bnQucG5n',
      alt: 'Flipkart',
      description: 'Flipkart - Prominent e-commerce company revolutionizing online shopping.',
      url: 'https://www.flipkart.com',
    },
    {
      src: 'https://imgs.search.brave.com/E1WfkgFn3kGp6oEdBz5WztWcejv6AEjKyEc79bkOxMY/rs:fit:500:0:0:0/g:ce/aHR0cHM6Ly93d3cu/Y2l0eXBuZy5jb20v/cHVibGljL3VwbG9h/ZHMvcHJldmlldy9o/ZC1wbmctcGF5dG0t/d2hpdGUtbG9nby03/MDE3NTE2OTQ3MDY2/MDRtend5ZmJoZzI3/LnBuZz92PTIwMjQw/NzE3MDY',
      alt: 'Paytm',
      description: 'Paytm - Major digital payments platform offering diverse financial services.',
      url: 'https://www.paytm.com',
    },
   
  ];

  const [clients, setClients] = useState(allClients.slice(0, 10));
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreClients = async () => {
    if (clients.length >= allClients.length) {
      setHasMore(false);
      return;
    }

    await new Promise((resolve) => setTimeout(resolve, 500));

    const newClients = allClients.slice(clients.length, clients.length + 10);
    setClients((prevClients) => [...prevClients, ...newClients]);
  };

  const handleClientClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <section id="clients" className="py-12 bg-gray-100">
      <div className="container mx-auto px-4">
        <h2 className="text-center text-3xl font-bold mb-8">
          Our Clients
        </h2>
        <p className="text-center text-lg mb-8">
          We have served numerous satisfied clients over the years.
        </p>
        <InfiniteScroll
          dataLength={clients.length}
          next={fetchMoreClients}
          hasMore={hasMore}
          loader={<h4>Loading more clients...</h4>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>You have seen all clients</b>
            </p>
          }
          style={{ overflow: 'hidden' }} 
        >
          <div className="clients-grid-container">
            <div className="clients-grid">
              {clients.map((client, index) => (
                <div
                  key={index}
                  className="client-card"
                  onClick={() => handleClientClick(client.url)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={client.src}
                    alt={client.alt}
                    className="client-image"
                  />
                  <p className="client-description">{client.description}</p>
                </div>
              ))}
            </div>
          </div>
        </InfiniteScroll>
      </div>
    </section>
  );
}

export default Clients;