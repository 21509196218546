import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDqqDfMnoPBp1S5ML-79A1fbxJmPwZGwz4",
  authDomain: "sheelapackersandmovers-256c5.firebaseapp.com",
  projectId: "sheelapackersandmovers-256c5",
  storageBucket: "sheelapackersandmovers-256c5.appspot.com",
  messagingSenderId: "805438930074",
  appId: "1:805438930074:web:23e694065263d606d9b323",
  measurementId: "G-90RD6WWFNG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Firebase Analytics if needed
const analytics = getAnalytics(app);

// Initialize Firestore and export it
const db = getFirestore(app); // Add this line if missing

export { analytics, auth, db }; // Ensure `db` is exported
